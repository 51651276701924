<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12" sm="10">
          <b-row>
            <b-col class="mb-2" cols="12" xl="4" md="6">
              <small>ID</small><br/>
              <b-input plaintext v-model="item.id"></b-input>
            </b-col>
            <b-col class="mb-2" cols="12" xl="4" md="6">
              <small>Group</small><br/>
              <b-input plaintext :value="teamNameMap[item.teamId] || item.teamId"></b-input>
            </b-col>
            <b-col class="mb-2" cols="12" xl="4" md="6">
              <small>Name</small><br/>
              <b-input v-model="item.name" autocomplete="off"></b-input>
            </b-col>
            <b-col class="mb-2" cols="12" xl="4" md="6">
              <small>Email</small><br/>
              <b-input-group class="">
                <b-input v-model="item.email" :readonly="readonly.email" autocomplete="off"></b-input>
                <b-input-group-append v-if="item.email === item.email_auth && item.email_txid">
                  <b-button variant="secondary" disabled>
                    인증됨
                  </b-button>
                </b-input-group-append>
                <template v-else>
                  <b-input-group-append v-if="item.email !== item_org.email || !item_org.email_checked_dt">
                    <b-button class="" variant="success" @click="sendAuthEmail" :disabled="busy.sendEmail || busy.waitEmail">
                      <span v-if="item.email_sended && item.email === item.email_sended">
                        재발송
                      </span>
                      <span v-else>
                        인증메일발송
                      </span>
                      <b-spinner class="mr-1" small v-if="busy.sendEmail"></b-spinner>
                    </b-button>
                  </b-input-group-append>
                </template>
              </b-input-group>
              <template v-if="item.email_sended && item.email === item.email_sended && !item.email_txid">
                <b-input-group>
                  <b-input v-model="item.emailAuthCode" :placeholder="emailLimitSecond ?
                   `${Math.floor(emailLimitSecond / 60)}:${(emailLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
                    `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
                  <b-input-group-append>
                    <b-button class="" variant="primary" @click="checkAuthEmail" :disabled="busy.checkEmail || !emailLimitSecond">
                      인증
                      <b-spinner class="mr-1" small v-if="busy.checkEmail"></b-spinner>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-col>
            <b-col class="mb-2" cols="12" xl="4" md="6">
              <small>휴대폰 번호(숫자로만 입력해주세요)</small><br/>
              <b-input-group class="">
                <b-input v-model="item.mobile" :readonly="readonly.mobile" autocomplete="off"></b-input>
                <b-input-group-append v-if="item.mobile === item.mobile_auth && item.mobile_txid">
                  <b-button variant="secondary" disabled>
                    인증됨
                  </b-button>
                </b-input-group-append>
                <template v-else>
                  <b-input-group-append v-if="item.mobile !== item_org.mobile || !item_org.mobile_checked_dt">
                    <b-button class="" variant="success" @click="sendAuthSMS" :disabled="busy.sendSMS || busy.waitSMS">
                      <span v-if="item.mobile_sended && item.mobile === item.mobile_sended">
                        재발송
                      </span>
                      <span v-else>
                        인증문자발송
                      </span>
                      <b-spinner class="mr-1" small v-if="busy.sendSMS"></b-spinner>
                    </b-button>
                  </b-input-group-append>
                </template>
              </b-input-group>
              <template v-if="item.mobile_sended && item.mobile === item.mobile_sended && !item.mobile_txid">
                <b-input-group>
                  <b-input v-model="item.smsAuthCode" :placeholder="smsLimitSecond ?
                   `${Math.floor(smsLimitSecond / 60)}:${(smsLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
                    `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
                  <b-input-group-append>
                    <b-button class="" variant="primary" @click="checkAuthSMS" :disabled="busy.checkSMS || !smsLimitSecond">
                      인증
                      <b-spinner class="mr-1" small v-if="busy.checkSMS"></b-spinner>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-col>
            <b-col class="mb-2" cols="12" xl="6">
              <small>이미지 링크</small><br/>
              <b-input v-model="item.img" autocomplete="off"></b-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6" sm="2">
          <img :src="item.img || 'https://i.balaan.io/blank/noimg_goods_200.webp'" onerror="this.src = 'https://i.balaan.io/blank/noimg_goods_200.webp'" class="w-100"/>
        </b-col>
      </b-row>
      <b-button variant="success" @click="save()">
        저장
      </b-button>
    </b-card>

    <b-card>
      <h5 class="bold mb-3">비밀번호 변경</h5>
      <div style="height: 0px; overflow: hidden">
        <!-- 휴대폰번호에 id 가 들어가는 auto complete 방지 -->
        <b-input type="text"></b-input>
      </div>
      <div class="grid">
        <div class="td col-form-label">기존 비밀번호</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.pw"></b-input>
        </div>
        <div class="td col-form-label">새 비밀번호</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.new_pw"></b-input>
        </div>
        <div class="td col-form-label">새 비밀번호 확인</div>
        <div class="td">
          <b-input type="password" class="w-200px" v-model="form.new_pw_again"></b-input>
        </div>
      </div>

      <b-button variant="success" @click="changePW()" :disabled="busy.pw">
        비밀번호 변경
        <b-spinner class="mr-1" small v-if="busy.pw"></b-spinner>
      </b-button>
    </b-card>

    <b-card>
      <h5 class="bold mb-3">Google OTP 등록 <b-badge v-if="$S.user.otp_reg_dt" variant="success">등록됨</b-badge></h5>
      <div class="mb-2">
        Google OTP는 고정된 비밀번호가 아닌 무작위로 생성되는 일회용 비밀번호를 이용하는 본인 인증 수단입니다.<br/>
        Google OTP를 활성화해서 사용하신다면 문자 / Email 로 코드를 받는것보다 간편한 사용이 가능합니다.<br/>
        먼저 Google OTP 앱을 스토어에서 다운받아 설치 후 진행해주세요.<br/>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Play Store (안드로이드)</a> <br/>
        <a href="https://apps.apple.com/app/google-authenticator/id388497605" target="_blank">App Store (iOS)</a> <br/>
        <br/>
        <a href="https://support.playtera.co.kr/hc/ko/articles/360003691315-OTP-%EC%9D%B8%EC%A6%9D-%EC%95%B1-%EB%93%B1%EB%A1%9D-%EB%B0%A9%EB%B2%95" target="_blank">(참고링크)</a>
      </div>
      <template v-if="!$S.user.otp_reg_dt">
        <img v-if="form.qr" :src="form.qr" style="border: 1px solid #000" alt="QR 이미지"/><br/>
        <!--      <b-input-group class="mt-2 w-300px">
                <b-form-input maxlength="6" class="form-control" v-model="form.otpSecret" placeholder="설정 키"/>
                <b-input-group-append><b-button variant="primary" @click="$utils.copyAlert(form.otpSecret)">설정 키 복사</b-button></b-input-group-append>
              </b-input-group>-->
        <b-input-group class="mt-2 w-300px">
          <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
          <b-form-input maxlength="6" class="form-control" v-model="form.otpCode" placeholder="OTP 코드 입력(6자리)" @keypress.enter="registOtp"/>
          <b-input-group-append><b-button variant="success" @click="registOtp">등록하기</b-button></b-input-group-append>
        </b-input-group>
      </template>
      <template v-else>
        <b-input-group class="mt-2 w-300px">
          <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
          <b-form-input maxlength="6" class="form-control" v-model="form.otpCode" placeholder="OTP 코드 입력(6자리)" @keypress.enter="registOtp"/>
          <b-input-group-append><b-button variant="success" @click="verify">인증 테스트</b-button></b-input-group-append>
        </b-input-group>
        <b-btn class="mt-2" variant="warning" @click="resetOtp">OTP 초기화</b-btn>
      </template>
    </b-card>

    <b-card>
      <h5 class="bold mb-3">로그인 목록</h5>
      <b-table-simple>
        <b-thead head-variant="light">
          <b-tr class="text-center">
            <b-th>운영체제</b-th>
            <b-th>브라우저</b-th>
            <b-th>로그인 IP</b-th>
            <b-th>최근 접속일시</b-th>
            <b-th>최초 로그인 일시</b-th>
            <b-th>관리</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="text-center" v-for="s in sessions" :key="s.id">
            <b-td>{{s.ua.os.name}} {{s.ua.os.version}}</b-td>
            <b-td>{{s.ua.browser.name}}</b-td>
            <b-td>{{s.ip}}</b-td>
            <b-td>{{$moment(new Date - Math.max(0, s.last) * 1000).format('YYYY-MM-DD HH:mm:ss')}}</b-td>
            <b-td>{{s._dt}}</b-td>
            <b-td>
              <span v-if="s.me">지금 접속 중</span>
              <b-btn v-else size="sm" @click="logout(s.id)">로그아웃</b-btn>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 130px 1fr;
  margin: 10px 0;
}
.grid .td {
  margin-bottom: 5px;
}
</style>

<script>

const CHECK_COL = 'email,email_txid,mobile,mobile_txid,name,img'.split(',');

export default {
  name: 'MyPage',
  title: '마이페이지',
  data() {
    return {
      form: {
        shop: [],
        category: [],
        brand: [],
        group: ['launch_date', 'discount_rate'],
        filter: [],
        pw: '',
        new_pw: '',
        new_pw_again: '',
        qr: '',
        otpSecret: '',
        otpCode: '',
      },
      item: {},
      item_org: {},
      sessions: [],
      teamNameMap: this.$utils.arr2map(this.$C.TEAMS, 'id', 'text'),
      emailLimitSecond: 180,
      smsLimitSecond: 180,
      busy: {
        me: false,
        save: false,
        pw: false,
        sendEmail: false,
        waitEmail: false,
        checkEmail: false,
        sendSMS: false,
        waitSMS: false,
        checkSMS: false,
        qr: false,
        verify: false,
      },
      handle: {
        waitEmail: null,
        expireEmail: null,
        waitSMS: null,
        expireSMS: null,
      },
      readonly: {
        email: false,
        mobile: false,
      }
    }
  },
  async created() {
    this.getMe();
    if (!this.$S.user.otp_reg_dt) {
      this.generateQrCode();
    }
  },
  methods: {
    async getMe() {
      // console.log('getMe');
      this.busy.me = true;
      let j = await this.$api.getJson('/user/me');
      this.busy.me = false;
      if (!j) return;
      this.item_org = j.user;
      this.item = this.$utils.clone(j.user);
      this.sessions = j.sessions;
    },
    async sendAuthEmail() {
      if (!this.item.email.match(/^[a-zA-Z0-9+\-_.]+@([-a-zA-Z0-9]+\.)+[-a-zA-Z0-9]+$/)) {
        this.$utils.alert('Email 을 형식에 맞게 입력해주세요');
        return;
      }
      clearTimeout(this.handle.waitEmail);
      clearInterval(this.handle.expireEmail);
      this.busy.sendEmail = true;
      let j = await this.$api.postJson('/user/sendAuthEmail', {email: this.item.email});
      this.busy.sendEmail = false;
      if (j) {
        this.busy.waitEmail = true;
        this.emailLimitSecond = 180;
        // 다량발송 방지
        this.handle.waitEmail = setTimeout(() => {
          this.busy.waitEmail = false;
          this.$forceUpdate();
        }, 15000);
        // 입력한도시간 표시
        this.handle.expireEmail = setInterval(() => {
          if (--this.emailLimitSecond <= 0) clearInterval(this.handle.expireEmail);
        }, 1000);
        this.item.email_sended = this.item.email;
        this.$utils.alert('인증메일이 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async checkAuthEmail() {
      if (!this.item.emailAuthCode) return alert('인증번호를 입력해주세요');
      const email = this.item.email;
      let j = await this.$api.postJson('/user/checkAuthEmail', {email, authCode: this.item.emailAuthCode});
      if (j) {
        this.item.email_auth = email;
        this.item.email_txid = j.txid;
        this.readonly.email = true;
        clearInterval(this.handle.expireEmail);
        this.$utils.alert('Email 이 인증되었습니다');

        // mypage 에서는 인증과 동시에 저장한다.
        this.busy.save = true;
        const res = await this.$api.postJson('/user/updateMe', {item: {email, email_txid: j.txid}});
        this.busy.save = false;
        if (res) {
          this.$S.user.email = email;
          localStorage.setItem('user', JSON.stringify(this.$S.user));
        }
      }
    },
    async sendAuthSMS() {
      if (!this.item.mobile || this.item.mobile.match(/\D/)) {
        this.$utils.alert('휴대폰 번호는 숫자로만 입력해주세요');
        return;
      }
      if (!this.item.mobile.match(/^(820|82|0)[157]\d[2-9]\d{6,8}/)) {
        this.$utils.alert('휴대폰 번호는 문자를 받을 수 있는 번호로 입력해주세요');
        return;
      }
      clearTimeout(this.handle.waitSMS);
      clearInterval(this.handle.expireSMS);
      this.busy.sendSMS = true;
      let j = await this.$api.postJson('/user/sendAuthSMS', {mobile: this.item.mobile});
      this.busy.sendSMS = false;
      if (j) {
        this.busy.waitSMS = true;
        this.smsLimitSecond = 180;
        // 다량발송 방지
        this.handle.waitSMS = setTimeout(() => {
          this.busy.waitSMS = false;
          this.$forceUpdate();
        }, 15000);
        // 입력한도시간 표시
        this.handle.expireSMS = setInterval(() => {
          if (--this.smsLimitSecond <= 0) clearInterval(this.handle.expireSMS);
        }, 1000);
        this.item.mobile_sended = this.item.mobile;
        this.$utils.alert('인증문자가 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async checkAuthSMS() {
      if (!this.item.smsAuthCode) return alert('인증번호를 입력해주세요');
      const mobile = this.item.mobile;
      let j = await this.$api.postJson('/user/checkAuthSMS', {mobile, authCode: this.item.smsAuthCode});
      if (j) {
        this.item.mobile_auth = mobile;
        this.item.mobile_txid = j.txid;
        this.readonly.mobile = true;
        clearInterval(this.handle.expireSMS);
        this.$utils.alert('휴대폰 번호가 인증되었습니다');

        // mypage 에서는 인증과 동시에 저장한다.
        this.busy.save = true;
        const res = await this.$api.postJson('/user/updateMe', {item: {mobile, mobile_txid: j.txid}});
        this.busy.save = false;
        if (res) {
          this.$S.user.mobile = mobile;
          localStorage.setItem('user', JSON.stringify(this.$S.user));
        }
      }
    },
    async save() {
      let modifiedCols = CHECK_COL.filter(e => JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (modifiedCols.length) {
        const item = {id: this.item.id};
        modifiedCols.forEach(e => item[e] = this.item[e]);
        if (modifiedCols.includes('email') || modifiedCols.includes('email_txid')) {
          if (!this.item.email_txid || this.item.email_auth !== this.item.email) {
            this.$utils.alert('Email 인증을 먼저 진행해주세요');
            return;
          }
          item.email = this.item.email;
        }
        if (modifiedCols.includes('mobile')) {
          if (!this.item.mobile_txid || this.item.mobile_auth !== this.item.mobile) {
            this.$utils.alert('휴대폰 인증을 먼저 진행해주세요');
            return;
          }
        }
        this.busy.save = true;
        const j = await this.$api.postJson('/user/updateMe', {item});
        this.busy.save = false;
        if (j) {
          this.$utils.alert('저장되었습니다');
          modifiedCols.forEach(e => this.$S.user[e] = item[e]);
          localStorage.setItem('user', JSON.stringify(this.$S.user));
          this.getMe();
          this.readonly.email = false;
          this.readonly.mobile = false;
        }
      } else {
        this.$utils.alert('변경된 사항이 없습니다');
      }
    },
    async changePW() {
      if (this.form.pw.length < 8) return this.$utils.alert('기존 비밀번호를 8자 이상으로 입력해주세요');
      // if (this.form.new_pw.length < 8) return this.$utils.alert('새 비밀번호를 8자 이상으로 입력해주세요');
      if (!this.checkPW(this.form.new_pw)) {
        return this.$utils.alert('새 비밀번호는 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상의 길이 혹은 10자리 이상의 길이로 입력해주세요');
      }
      if (this.form.new_pw !== this.form.new_pw_again) return this.$utils.alert(`새 비밀번호는 새 비밀번호 확인과 동일해야 합니다`);
      if (this.form.new_pw === this.form.pw) return this.$utils.alert(`기존 비밀번호와 동일한 새 비밀번호는 사용하실 수 없습니다.`);

      this.busy.pw = true;
      let j = await this.$api.postJson('/user/changePW', {pw: this.$utils.sha256(this.form.pw), new_pw: this.$utils.sha256(this.form.new_pw)});
      this.busy.pw = false;
      if (j) {
        await this.$utils.alert('비밀번호가 변경되었습니다');
        this.form.pw = this.form.new_pw = this.form.new_pw_again = '';
      }
    },
    /**
     * 새롭게 나온 kisa 패스워드 선택 및 이용 안내서(2019.06) 에 따른 체크
     * https://skyksit.tistory.com/entry/%EB%B9%84%EB%B0%80%EB%B2%88%ED%98%B8-%EC%B2%B4%EA%B3%84-%EB%B3%80%EA%B2%BD-%EB%90%A8-11%EB%85%84-%EB%A7%8C%EC%97%90
     * 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상
     * 한 가지 종류로 10자 이상
     * 6개월마다 변경 필요없음
     *
     * @param {string} pw
     * @return {boolean}
     */
    checkPW(pw) {
      // 문자그룹별 카운트
      const lower = pw.match(/[a-z]/);
      const upper = pw.match(/[A-Z]/);
      const num = pw.match(/[0-9]/);
      const etc = pw.match(/[^0-9a-zA-Z]/);
      const count = [lower, upper, num, etc].filter(e => e).length;
      return count >= 2 && pw.length >= 8 || pw.length >= 10;
    },
    async generateQrCode() {
      this.busy.qr = true;
      const j = await this.$api.postJson('/user/otp/generate', {});
      this.busy.qr = false;
      if (j) {
        this.form.qr = j.data.qrData;
        this.form.otpSecret = j.data.secret;
      }
    },
    async registOtp() {
      if (this.form.otpCode.length !== 6) return alert('OTP 코드를 6자리로 입력해주세요');
      this.busy.verify = true;
      const j = await this.$api.postJson('/user/otp/registOtp', {code: this.form.otpCode, secret: this.form.otpSecret});
      this.busy.verify = false;
      if (j) {
        this.$alertTop('성공적으로 인증되었습니다.');
        this.form.otpSecret = '';
        this.form.otpCode = '';
        this.$S.user.otp_reg_dt = j.otp_reg_dt;
      }
    },
    async verify() {
      if (this.form.otpCode.length !== 6) return alert('OTP 코드를 6자리로 입력해주세요');
      this.busy.verify = true;
      const j = await this.$api.postJson('/user/otp/verify', {code: this.form.otpCode});
      this.busy.verify = false;
      if (j) {
        this.$alertTop('성공적으로 인증되었습니다.');
        this.form.otpCode = '';
      }
    },
    async resetOtp() {
      if (!confirm('OTP 를 초기화 하시겠습니까?')) return;

      this.form.qr = '';
      this.busy.resetOtp = true;
      const j = await this.$api.postJson('/user/resetOtp');
      this.busy.resetOtp = false;
      if (j) {
        delete this.$S.user.otp_reg_dt;
        this.$alertTop('초기화 되었습니다');
        this.generateQrCode();
        this.$forceUpdate();
      }
    },
    async logout(id) {
      const j = await this.$api.postJson('/user/revokeSession', {id});
      if (j) {
        this.getMe();
      }
    }
  }
}
</script>

